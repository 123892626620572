import { Component, OnInit, Input, ElementRef, ViewChild, AfterViewChecked } from '@angular/core';
import { RouterModule, Router } from '@angular/router';
import { UtilsService } from 'src/app/services/utils.service';
import { MenuService, Menu } from 'src/app/services/menu.service';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import config from 'src/app/configuration_file';

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {

    public closed_menu = true;
    public User: any;
    public edit_permission = false;
    public Menu;
    public Permissions = {
        documents_control: false,
        audits_and_inspections: false,
        incident_management: false,
        administrative_management: false,
        risk_management: false,
        continuous_improvement: false,
    }
    public userSubscription: any;
    public lightLogoRoute = config.logo === 'default' ? '../../../assets/resources/logos/Logo-B-M3.png' : '../../../assets/resources/logos/custom/' + config.business_name + '_light.png';
    public config = config;
    public permissions = {}
    public minScreenWidthForRetractableMenu = 1110;
    public mouseInContainer = window.innerWidth > this.minScreenWidthForRetractableMenu ? false : true;

    constructor(
        private Router: Router,
        private MenuService: MenuService,
        private UtilsService: UtilsService,
        private userService: UserService
    ) {
    }

    async ngOnInit(): Promise<void> {
        const permissions = [
            'documentos.acceso_indicadores',
            'documentos.acceso_listado_maestro',
            'documentos.crear_documentos_internos',
            'documentos.crear_documentos_externos',
            'usuarios.acceso_directorio',
            'usuarios.crear_roles',
        ];
        const permissionsResponse = await firstValueFrom<any>(this.userService.confirmAccess({token: localStorage.getItem('id_token'), permissions}));
        for (const permission of permissions) {
            this.permissions[permission] = permissionsResponse.data[permission];
        }

        this.getUser();
        this.setPermissions();
        this.MenuService.getMenuObservable().subscribe((Menu) => {
            if (Menu) {
                this.closed_menu = Menu.getMenu();
            }
            this.setMenuStyle();
        });
        this.UtilsService.setUserHeader();
        this.UtilsService.setMenu();
        await this.isAdmin();

        // subscribe to the window resize event
        window.addEventListener('resize', () => {
            // Only run when window width is smaller than 576px
            if (window.innerWidth > this.minScreenWidthForRetractableMenu) {
                this.mouseInContainer = false;
            } else {
                this.mouseInContainer = true;
            }
        });
    }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    getUser(): void {
        this.userSubscription = this.userService.retrieveUser().subscribe((user) => {
            if (user) {
                this.User = user;
            }
        });
    }

    closeMenu(){
        this.closed_menu = !this.closed_menu;
        localStorage.setItem('menu', this.closed_menu ? 'true' : 'false');
        this.setMenuStyle();

        this.MenuService.closeMenu();
    }

    openPage(page_open) {
        if(window.innerWidth < 576) {
            this.closeMenu();
        }
        this.Router.navigateByUrl(page_open);
    }

    isAdmin(){
        //console.log(this.User);
        if (parseInt(this.User?.role_id) == 1) {
            return true
        }else{
            return false
        }
    }

    setPermissions() {
        if (parseInt(this.User.role_id) == 1) {
            this.Permissions = {
                documents_control: true,
                audits_and_inspections: true,
                incident_management: true,
                administrative_management: true,
                risk_management: true,
                continuous_improvement: true
            }
        }
        for (const module of this.User.modules) {
            switch (module.module_id) {
                case 1:
                    this.Permissions.documents_control = true;
                    if(module.user_module_permission.find(permission => permission.permission_id == 2)){
                        this.edit_permission = true;
                    }
                    break;
                case 2:
                    this.Permissions.audits_and_inspections = true;
                    break;
                case 3:
                    this.Permissions.incident_management = true;
                    break;
                case 4:
                    this.Permissions.administrative_management = true;
                    break;
                case 5:
                    this.Permissions.continuous_improvement = true;
                    break;
                case 6:
                    this.Permissions.risk_management = true;
                    break;
                default:
                    break;
            }
        }
    }

    setMenuStyle() {
        try {
            if(this.closed_menu){
                document.getElementById('grid').style.marginLeft = '2vw';
            }else {
                document.getElementById('grid').style.marginLeft = '65px';
            }
        } catch(error){}
    }

    handleMouseEnterMenu() {
        // Only run when window width is smaller than 576px
        if (window.innerWidth > this.minScreenWidthForRetractableMenu) {
            this.mouseInContainer = true;
        }
    }

    handleMouseLeaveMenu() {
        // Only run when window width is greater than 576px
        if (window.innerWidth > this.minScreenWidthForRetractableMenu) {
            this.mouseInContainer = false;
            const elements = document.getElementsByClassName('expandable-menu');
            for (let i = 0; i < elements.length; i++) {
                elements[i].classList.remove('show');
            }
        }
    }
}
