import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComponentInsideLayout } from '../inside-layout/component';
import { RouterModule } from '@angular/router';
import { HeaderModule} from 'src/components/header/header.module';
import { MenuModule} from 'src/components/menu/menu.module';
import { NotificationsSidebarModule } from 'src/components/notifications-sidebar/notifications-sidebar.module';
@NgModule({
  declarations: [
    ComponentInsideLayout,
    ],
  imports: [
    CommonModule,
    RouterModule,
    HeaderModule,
    MenuModule,
    NotificationsSidebarModule
  ],

  exports: [
    ComponentInsideLayout
  ],
  providers: []
})
export class InsideLayoutModule { }
