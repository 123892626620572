import { Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, Observable, of, switchMap, tap } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    private user$ = new BehaviorSubject(null);
    userObservable = this.user$.asObservable();

    constructor(
        public ApiService: ApiService
    ) { }

    public initUser() {
        const token = localStorage.getItem('id_token');

        if (token) {
            this.authenticate({id_token: token}).subscribe((res: any) => {
                if (res.success) {
                    this.user$.next(res.user);
                } else {
                    this.user$.next(null);
                }
            });
        } else {
            this.user$.next(null);
        }
    }

    public retrieveUser(): Observable<any> {
        if (this.user$.value) {
            return this.user$;
        } else {
            return this.user$.asObservable().pipe(
                switchMap((user) => {
                    if (user) {
                        return this.user$;
                    } else {
                        if (!localStorage.getItem('id_token')) {
                            return this.user$;
                        }
                        return this.authenticate({id_token: localStorage.getItem('id_token')}).pipe(
                            tap((res: any) => {
                                if (res.success) {
                                    this.user$.next(res.user);
                                } else {
                                    this.logout();
                                }
                            })
                        );
                    }
                })
            );
        }
    }

    public logout() {
        localStorage.removeItem('id_token');
        localStorage.removeItem('expires_at');
        this.user$.next(null);
    }

    confirmAccess(params): any { params.url = "confirm_access"; return this.ApiService.post(params); }
    login(params): any { params.url = "login"; return this.ApiService.post(params); }
    authenticate(params): any { params.url = "authenticate"; return this.ApiService.post(params); }
    create(params): any { params.url = "user"; return this.ApiService.post(params); }
    requestUser(params): any { params.url = "request_user"; return this.ApiService.post(params); }
    getListUsersRequest(params): any { params.url = "list_request_user"; return this.ApiService.get(params,true); }
    deleteUsersRequest(params): any { params.url = "request_user"; return this.ApiService.delete(params); }
    verifyMail(params): any { params.url = "confirm_mail"; return this.ApiService.post(params); }
    sendConfirmMail(params): any { params.url = "send/mail"; return this.ApiService.post(params); }
    recoverPass(params): any { params.url = "recover_pass"; return this.ApiService.post(params); }
    changePass(params): any { params.url = "change_pass"; return this.ApiService.post(params); }
    getListUsers(params): any { params.url = "list_user"; return this.ApiService.get(params,true); }
    getUser(params): any { params.url = "user"; return this.ApiService.get(params); }
    update(params): any { params.url = "update_user"; return this.ApiService.post(params); }
    updateData(params): any { params.url = "update_user_data"; return this.ApiService.post(params); }
    saveImage(params): any { params.url = "save_image"; return this.ApiService.post(params); }

    transferAccount(params): any { params.url = "transfer_account"; return this.ApiService.post(params); }
    deleteAccount(params): any { params.url = "delete_user"; return this.ApiService.delete(params); }

    userSignature(params): any { params.url = "user_signature"; return this.ApiService.post(params); }//postFormData
}
