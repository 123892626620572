import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, concatMap, map, merge, Observable, of, scan, Subject, switchMap, tap, mergeMap, catchError, forkJoin } from 'rxjs';
import { ApiService } from './api.service';
import { UserService } from './user.service';
import config from '../configuration_file';

@Injectable({
    providedIn: 'root'
})
export class NotificationService {
    private user$ = this.userService.retrieveUser();
    
    private notificationSidebarOpenedSubject = new BehaviorSubject<boolean>(false);
    public notificationSidebarOpened$ = this.notificationSidebarOpenedSubject.asObservable();

    private sidebarOffset$ = new BehaviorSubject<number>(0);
    sidebarNotifications$ = combineLatest([this.user$, this.sidebarOffset$]).pipe(
        switchMap(([user, offset]) => {
            return this.getNextNotifications(user, null, offset);
        }),
        catchError(error => {
            console.error('Error en la obtención de notificaciones:', error);
            return of([]);
        }),
        scan((prevNotifications, currentNotifications) => {
            return [...prevNotifications, ...currentNotifications];
        }, [])
    );
    

    private offset$ = new BehaviorSubject(0);
    private filters$ = new BehaviorSubject({
        searchTerm: '',
        dateRange: {
            start: null,
            end: null
        },
        order: {
            col: '',
            reverse: false
        }
    });
    private resetNotifications$ = new Subject();
    private gotLastNotification = false;

    filteredNotifications$ = this.filters$.pipe(
        mergeMap((filters) => {
          return combineLatest([this.user$, this.offset$]).pipe(
            mergeMap(([user, offset]) => {
              return this.getNextNotifications(user, filters, offset);
            })
          );
        })
      );

    private deleteNotificationSubject = new Subject<number>();
    deleteNotificationAction$ = this.deleteNotificationSubject.asObservable();

    allNotifications$ = merge(
        this.filteredNotifications$,
        this.deleteNotificationAction$.pipe(
            concatMap((id) => {
                return this.deleteNotification({ id }).pipe(
                    map(_ => id)
                );
            })
        ),
        this.resetNotifications$
    ).pipe(
        scan<any[] | number | symbol, any[]>((prevNotifications, currentNotification) => {
            if (typeof currentNotification === 'symbol') {
                return [];
            } else if (typeof currentNotification === 'number') {
                return prevNotifications.filter((notification) => notification.id !== currentNotification);
            } else {
                return [...prevNotifications, ...currentNotification];
            }
        }, [])
    );
    
    public allNotifications: any = this.sidebarNotifications$.pipe();
    public inboxNotis: any = this.filteredNotifications$.pipe();

    constructor(
        public userService: UserService,
        public apiService: ApiService
    ) { }

    getNextNotifications(user, filters, offset): Observable<any[]> {
        if (this.gotLastNotification === false) {
            if (filters) {
                const searchTerm = filters.searchTerm.toLowerCase();
                const startingDate = filters.dateRange.start != null ? filters.dateRange.start.toISOString().split('T')[0] + ' 0:0:0' : '1970-0-0 0:0:0';
                const endingDate = filters.dateRange.end != null ? filters.dateRange.end.toISOString().split('T')[0] + ' 23:59:59' : new Date().toJSON().split('T')[0] + ' 23:59:59';
                const col = filters.order.col;
                const order = filters.order.reverse ? 'ASC' : 'DESC';
                return this.getListNotifications({ to_user_id: user.id, offset, filter: searchTerm, starting_date: startingDate, ending_date: endingDate, col , order }).pipe(
                    map((res: any) => res.data),
                    map((notifications: any[]) => {
                        return notifications.filter((notification) => notification.status !== 0);
                    }),
                    map((notifications: any[]) => {
                        return this.changeNotificationsMessage(notifications);
                    }),
                    tap((notifications: any[]) => {
                        if (notifications.length < 10) {
                            this.gotLastNotification = true;
                        }
                    })
                );
            } else {
                return this.getListNotifications({ to_user_id: user.id, offset }).pipe(
                    map((res: any) => res.data),
                    map((notifications: any[]) => {
                        return notifications.filter((notification) => notification.status !== 0);
                    }),
                    map((notifications: any[]) => {
                        return this.changeNotificationsMessage(notifications);
                    }),
                    map((notifications: any[]) => {
                        return notifications.map((notification) => {
                            let folder_route = '';

                            if (notification.internal === 0 && config.supports_external_documents) {
                                if (notification.provider_name) {
                                    folder_route = notification.provider_name + `${notification.product_name ? (' > ' + notification.product_name) : ''}`;
                                } else {
                                    folder_route = 'Ruta no disponible';
                                }
                            } else if (notification.internal === 0 && !config.supports_external_documents) {
                                if (notification.area_name) {
                                    folder_route = notification.area_name + ' > ' + notification.document_template_name;
                                } else {
                                    folder_route = 'Ruta no disponible';
                                }
                            } else if (notification.internal === 1 && notification.area_name) {
                                folder_route = notification.area_name + ' > ' + notification.document_template_name;
                            } else {
                                folder_route = 'Ruta no disponible';
                            }

                            if (config.supports_external_documents) {
                                folder_route = notification.internal ?
                                'Documentos Internos > ' + folder_route
                                :
                                'Documentos Externos > ' + folder_route
                            }

                            notification.folder_route = folder_route;
                            return notification;
                        });
                    }),
                    tap((notifications: any[]) => {
                        if (notifications.length < 10) {
                            this.gotLastNotification = true;
                        }
                    })
                );
            }
        } else {
            return of([]);
        }
    }

    changeNotificationsMessage(notifications): any[] {
        return notifications.map((notification) => {
            if (notification.email === null) {
                notification.email = 'Sistema M3';
            }
            if (notification.table_id !== null) {
                if (notification.type === 'difussion_success' || notification.type === 'document_request_update') {
                    if (notification.document_code) {
                        notification.notification = notification.notification + ' - ' + notification.document_code;
                    }
                    notification.notification = notification.notification + ' ';
                } else if (notification.type === 'asign_diffusion' || notification.type === 'review' || notification.type === 'review_success' || notification.type === 'review_rejection' || notification.notification === 'Recordatorio de documento para revisar' || notification.page === '/document/diffusion-notification') {
                    if (notification.document_code && !notification.notification.includes('paquete')) {
                        notification.notification = notification.notification + ': ' + notification.document_name + ' - ' + notification.document_code;
                    }
                }
            }
            return notification;
        });
    }

    loadMoreNotifications() {
        this.offset$.next(this.offset$.value + 50);
    }

    loadMoreSidebarNotifications(): void {
        this.sidebarOffset$.next(this.sidebarOffset$.value + 10);
    }

    setFilters(searchTerm, dateRange, order): void {
        this.resetNotifications();
        this.resetOffset();
        this.filters$.next({ searchTerm, dateRange, order });
    }

    resetFilters(): void {
        this.resetNotifications();
        this.resetOffset();
        this.filters$.next({
            searchTerm: '',
            dateRange: {
                start: null,
                end: null
            },
            order: {
                col: '',
                reverse: false
            }
        });
    }

    resetOffset(): void {
        this.offset$.next(0);
    }

    resetNotifications(): void {
        this.gotLastNotification = false;
        this.resetNotifications$.next(Symbol());
    }

    getFilters(): Observable<any> {
        return this.filters$.asObservable();
    }

    deleteNotificationAction(id): void {
        this.deleteNotificationSubject.next(id);
    }

    openNotificationsSidebar(): void {
        this.notificationSidebarOpenedSubject.next(true);
    }

    closeNotificationsSidebar(): void {
        this.notificationSidebarOpenedSubject.next(false);
        this.sidebarOffset$.next(0);
    }

    deleteNotificationByTypeAndTableId(type: string, table_id: number): Observable<any> {
        return this.getListNotifications({ type, table_id }).pipe(
            concatMap((data: any) => {
                const deleteObservables = [] as Observable<any>[];
                for (const notification of data.data) {
                    deleteObservables.push(this.deleteNotification({ id: notification.id }));
                }
                return forkJoin(deleteObservables);
            })
        );
    }

    getNotification(params): any { params.url = 'notification'; return this.apiService.get(params, true); }
    getListNotifications(params): any { params.url = 'list_notification'; return this.apiService.get(params, true); }
    getNewerNotifications(params): any { params.url = 'new_notifications'; return this.apiService.get(params, true); }
    saveNotification(params): any { params.url = 'notification'; return this.apiService.post(params); }
    deleteNotification(params): any { params.url = 'notification'; return this.apiService.delete(params, true); }
}
