<header class="fixed-header">
    <div class="environment-flags" *ngIf="!isFrontInProd">
        <div class="ribbon-front ribbon-top-left-front">
            <span class="ribbon-safe">Front in test</span>
        </div>
        <div class="ribbon-back ribbon-top-left-back">
            <span *ngIf="!isBackInProd" class="ribbon-safe">Back in test</span>
            <span *ngIf="isBackInProd" class="ribbon-danger"
                >Back in production</span
            >
        </div>
        <p *ngIf="isFrontInProd"></p>
    </div>
    <nav
        class="navbar navbar-expand-lg navbar-light bg-transparent pt-0"
        style="margin-bottom: 20px"
        *ngIf="menu$ | async as menu"
    >
        <a
            class="navbar-brand"
            (click)="openPage('/')"
            *ngIf="menu.isOpen === false"
        >
            <img class="dark-logo" [src]="darkLogoRoute" alt="" />
        </a>
        <div class="title-container">
            <h2 class="ligth-green title">{{ getTitle() }}</h2>
        </div>

        <div class="info-container">
            <a
                class="nav-link navbar-toggle mt-2 notification-element"
                role="button"
                aria-label="openNotificationsSidebar"
                (click)="openNotificationsSidebar()"
            >
            <img
                class="notification-icon"
                [src]="mostrarNotificacion ? '../../../assets/resources/icons/notifications-red.png' : '../../../assets/resources/icons/notifications.png'"
                alt=""
            />
                <!-- src="{{ (Notifications.length > 0 && !isReadedNotifications())? '../../../assets/resources/icons/notifications-red.png' : '../../../assets/resources/icons/notifications.png' }}" -->
            </a>
            <a
                class="navbar-toggler my-auto"
                href="#"
                id="navbarDropdown"
                role="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <img
                    class="profile-icon pt-lg-0 pt-lg-2"
                    src="{{
                        User.image != null
                            ? photo_url + User.id + '/' + User.image
                            : '../../../assets/resources/icons/profile.png'
                    }}"
                    alt=""
                />
            </a>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item dropdown">
                        <a
                            class="nav-link dropdown-toggle user-name"
                            href="#"
                            id="navbarDropdown"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            {{ User.name }}
                            <img
                                class="profile-icon hide"
                                src="{{
                                    User.image != null
                                        ? photo_url + User.id + '/' + User.image
                                        : '../../../assets/resources/icons/profile.png'
                                }}"
                                alt=""
                            />
                        </a>
                        <div
                            class="dropdown-menu dropdown-menu-right"
                            aria-labelledby="navbarDropdown"
                        >
                            <a
                                class="dropdown-item"
                                (click)="openPage('/profile')"
                                >Perfil</a
                            >
                            <a class="dropdown-item" (click)="logout()"
                                >Cerrar sesión</a
                            >
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</header>
<!-- <div class="header-space"></div> -->
