import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserMenuService {
    public user: User = null;
    private userSubject = new BehaviorSubject(this.user);

    getUserObservable(): Observable<User> {
        return this.userSubject.asObservable();
    }

    public setUser(user: User) {
        this.user = user;
        this.userSubject.next(this.user);
    }

    setImage(image: string){
        this.user.image = image;
        this.userSubject.next(this.user);
    }

    getImage(){
        return this.user.image;
    }

}

export class User {
    public name: string;
    public id: string;
    public paternal_surname: string;
    public maternal_surname: string;
    public email: string;
    public image: string;
    public role_id: number;

    constructor(id: string, name: string, paternal_surname: string, maternal_surname: string, email: string, role_id: number = 1) {
        this.id = id;
        this.name = name;
        this.paternal_surname = paternal_surname;
        this.maternal_surname = maternal_surname;
        this.email = email;
        this.role_id = role_id;
    }

    getUser() {
        return {
            id: this.id,
            name: this.name,
            paternal_surname: this.paternal_surname,
            maternal_surname: this.maternal_surname,
            email: this.email,
            image: this.image,
            role_id: this.role_id
        };
    }

    getImage(){
        return this.image;
    }
}