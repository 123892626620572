<div class="backdrop" (click)="closeNotificationsSidebar()"></div>

<div id="sidebar" #sidebar class="sidebar-container">
    <div class="sidebar-header">
        <h4 class="sidebar-title">Notificaciones</h4>

        <button type="button" class="close" (click)="closeNotificationsSidebar()" aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
        <div style="width: 100%; display: flex; align-items: center; justify-content: center;">
            <ng-select style="margin-top: 1em; width: 90%;" 
                bindLabel="name"
                bindValue="type"
                [items]="notificationTypes" 
                [multiple]="true" 
                [closeOnSelect]="false" 
                placeholder="Seleccionar tipo de notificación"
                (change)="onNotificationTypeSelect($event)">
            </ng-select>
        </div>
        
    </div>
    <div class="sidebar-content" #sidebarcontent (scroll)="onScroll($event)">
        <div class="notifications-container" #notificationslist *ngIf="allNotifications">
            <div *ngFor="let notification of allNotifications">
                <app-notification-item [notification]="notification" (closeSidebarEvent)="closeNotificationsSidebar()"></app-notification-item>
            </div>
            <div *ngIf="allNotifications.length == 0" class="user-notification-name align-content-center text-center">
                <p id="notificationMessage">Sin notificaciones.</p>
            </div>
            <div *ngIf="busyGettingNotifications" class="d-flex justify-content-center" style="margin: 20px 0px 20px 0px">
                <div class="spinner-border text-secondary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
        </div>
    </div>
</div>