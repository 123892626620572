import { Injectable } from '@angular/core';
import config from './configuration_file';

@Injectable()
export class AppConfig {
    static production: boolean                      = true;

    static readonly api_local: string               = 'http://localhost:3000/';
    static readonly api_key_google: string          = 'API_KEY_GOOGLE&libraries=visualization,places,drawing';
    static api_url: string                          = (AppConfig.production) ? config.api_url : AppConfig.api_local;
    static web_url: string                          = (AppConfig.production) ? config.web_url : "http://localhost:4200/";
}