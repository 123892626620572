import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationsSidebarComponent } from './notifications-sidebar.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';
import { NgSelectModule } from '@ng-select/ng-select';

@NgModule({
  declarations: [NotificationsSidebarComponent, NotificationItemComponent],
  imports: [
    CommonModule,
    NgSelectModule
  ],
  exports: [
    NotificationsSidebarComponent,
  ]
})
export class NotificationsSidebarModule{ }
