import { Injectable } from '@angular/core';
import { AppConfig } from 'src/app/app.config';
import { ApiService } from './api.service';
import { UserMenuService, User } from 'src/app/services/user-header.service';
import { MenuService, Menu } from 'src/app/services/menu.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { Location, DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import * as Editor from 'src/components/ckeditor5/build/ckeditor';
import { firstValueFrom } from 'rxjs';
import { UserService } from './user.service';
import { NavigationService } from './navigation.service';
import { ActivatedRoute, Params, QueryParamsHandling, Router } from '@angular/router';
import * as exp from 'constants';
import config from '../configuration_file';
import { format } from 'date-fns';
import { es as esLocale } from 'date-fns/locale';

@Injectable({
    providedIn: 'root'
})
export class UtilsService {
    public Editor = Editor;
    public editorConfig = {
        toolbar: {
            items: [
                'heading', 'fontFamily', '|', 'bold', 'italic', 'underline',
                'link', 'fontSize', 'bulletedList', 'numberedList', 'fontBackgroundColor',
                'fontColor', 'highlight', '|', 'alignment', '|',
                'insertTable', 'removeFormat', 'undo', 'redo'
            ]
        },
        language: 'es',
        image: { toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side', 'linkImage'] },
        table: {
            contentToolbar: [
                'tableColumn', 'tableRow', 'mergeTableCells', 'tableCellProperties', 'tableProperties'
            ]
        },
    };
    private image_compress_options = { RATIO: 75, COMPRESS: 60 };
    userSubscription: any;

    constructor(
        private ApiService: ApiService,
        private UserMenuService: UserMenuService,
        private MenuService: MenuService,
        private NgxImageCompressService: NgxImageCompressService,
        private DatePipe: DatePipe,
        public Location: Location,
        private sanitizer: DomSanitizer,
        private userService: UserService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private navigationService: NavigationService,
    ) { }

    ngOnDestroy() {
        this.userSubscription.unsubscribe();
    }

    async goBottom(content) {
        await content.scrollToBottom(0);
        await content.scrollToBottom(0);
    }

    bypassSecurityTrustStyle(url): any {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    consoleLog(data: any, params?: any) {
        if (!AppConfig.production) {
            (params) ? console.log(data, params) : console.log(data);
        }
    }

    b64toBlob(b64Data, contentType, sliceSize) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    async setUserHeader(): Promise<void> {
        this.userSubscription = this.userService.retrieveUser().subscribe((user: any) => {
            if (user) {
                this.UserMenuService.setUser(new User(user.id, user.name, user.paternal_surname, user.maternal_surname, user.email, user.role_id));
                this.UserMenuService.setImage(user.photo);
            }
        });
    }

    setMenu() {
        const menuItem = localStorage.getItem('menu') === 'true';
        this.MenuService.setMenu(new Menu(menuItem));
    }

    selectFile = () => {
        //declaracion de la promesa.
        let promise = new Promise((resolve, reject) => {
            this.NgxImageCompressService.uploadFile().then(async ({ image, orientation }) => {
                if (this.NgxImageCompressService.byteCount(image) < 1048576) {
                    resolve(image);
                    console.warn('Size in bytes was:', this.NgxImageCompressService.byteCount(image));
                } else {
                    resolve(this.compressFile(image, orientation, this.image_compress_options.RATIO, this.image_compress_options.COMPRESS));
                }
            });
        });
        return promise;
    };

    compressFile(image, orientation, ratio, compress) {
        let promise = new Promise((resolve, reject) => {
            this.NgxImageCompressService.compressFile(image, orientation, ratio, compress).then(
                result => {
                    if (this.NgxImageCompressService.byteCount(result) < 1048576) {
                        // console.warn('Size in bytes was:', this.NgxImageCompressService.byteCount(result));
                        resolve(result);
                    } else {
                        resolve(this.compressFile(image, orientation, ratio, compress - 5));
                    }
                }
            );
        });
        return promise;

    }

    getFileType(file) {
        var block = file.split(";");
        var contentType = block[0].split(":")[1];
        return "." + contentType.split("/")[1];
    }

    fileB64toBlob(file) {
        var block = file.split(";");
        var contentType = block[0].split(":")[1];
        var realData = block[1].split(",")[1];
        var sliceSize = 512;

        var byteCharacters = atob(realData);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    isAdmin() {
        this.userSubscription = this.userService.retrieveUser().subscribe((user: any) => {
            if (user) {
                if (user.role_id !== 1) {
                    this.navigationService.goBackOrHome();
                }
            }
        });
    }

    async sleep(ms) {
        return await new Promise(resolve => setTimeout(resolve, ms));
    }

    mktime(hour?, minute?, month?, day?, year?) {
        if (hour && minute && month && day && year) {
            return ((new Date(year, month, day, hour, minute, 0)).getTime() / 1000).toFixed(0);
        } else {
            return (new Date().getTime() / 1000).toFixed(0);
        }
    }

    dateTransform(date, type?) { return this.DatePipe.transform((date) ? date : new Date(), (type) ? type : 'd/M/yy, h:m a'); }

    setSlides(data, rows) {
        let return_data = [];
        let slide = [];
        let count = 0;
        let total = 0;
        for (const key in data) {
            count++;
            total++;
            if (count % rows == 0 || total == data.length) {
                slide.push(data[key]);
                return_data.push(slide);
                count = 0;
                slide = [];
            } else {
                slide.push(data[key]);
            }
        }
        return return_data;
    }

    createUUID() {
        var dt = new Date().getTime();
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return uuid;
    }


    copyText(text: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = text;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
        console.log(text);

    }

    async getBase64ImageFromUrl(image_url: string): Promise<any> {
        try {
            const response = await fetch(image_url);
            if (!response.ok) {
                return '';
            }
            const blob = await response.blob();
            const base64Image = await this.readAsDataURL(blob);
            return base64Image;
        } catch (error) {
            console.error('Error en getBase64ImageFromUrl:', error);
            throw error;
        }
    }

    readAsDataURL(blob) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
            reader.readAsDataURL(blob);
        });
    }

    getCodeFromDocument(documentId) {
        return this.ApiService.get({ url: 'code_from_document/' + documentId });
    }

    getDocumentFromCode(code) {
        return this.ApiService.get({ url: 'document_from_code/' + code });
    }

    formatDocumentVersionList(documentVersionList, currentDocument) {
        const orderedVersions = documentVersionList.sort((a, b) => (a.version > b.version) ? 1 : -1);

        const versions = [];

        versions.push({ id: orderedVersions[0].document_update_id, version: 0, reason: 'Versión inicial' })

        for (let i = 0; i < orderedVersions.length; i++) {
            let id = 0;
            if (i === orderedVersions.length - 1) {
                id = currentDocument.document_update.id;
            } else {
                id = orderedVersions[i + 1].document_update_id;
            }
            const version = orderedVersions[i].version + 1;
            const reason = orderedVersions[i].reason;

            versions.push({ id, version, reason })
        }

        versions.reverse();

        return versions;
    }

    copyToClipboard(text) {
        navigator.clipboard.writeText(text);
    }

    redirectNewParam(params, handling: 'merge' | 'replace') {
        const currentRoute = this.activatedRoute.snapshot['_routerState'].url;

        const newMatrixParams = Object.keys(params).reduce((result, key) => {
            result[key] = params[key];
            return result;
        }, {});

        // Get last matrix parameters.
        if (currentRoute.includes(';') && handling === 'merge') {
            const matrixParams = currentRoute.split(';').slice(1).filter((item) => item !== '');

            matrixParams.forEach((matrixParam) => {
                const key = matrixParam.split('=')[0];
                const value = matrixParam.split('=')[1];

                if (!newMatrixParams[key]) {
                    newMatrixParams[key] = value;
                }
            });
        }

        // Get current route removing matrix parameters.
        const routeArray = this.activatedRoute.snapshot['_routerState'].url.split(';')[0].split('/').filter((item) => item !== '');

        // Append the matrix parameters to the current route.
        this.router.navigate(
            [
                ...routeArray,
                newMatrixParams
            ]
        );
    }

    getDocumentExpiration(document) {
        const today = new Date();
        const formatted_review = {
            formatted_start: today, // Se sobrescribirá más adelante
            color: 'green',
            status: "Vigente",
        };

        
        let emitionDate: any;
        if (document.emition_date != null) {
            var emition_date = document.emition_date;
            var parts = emition_date.split("T");
            var datePart = parts[0];
            var timePart = parts[1];
            if (timePart === '00:00:00.000Z') {
                timePart = '06:00:00.000Z';
            }
            var adjustedDateTime = datePart + 'T' + timePart;
            emitionDate = new Date(adjustedDateTime);

            //const emitionDate = new Date(document.emition_date);
            let expirationInt = document.expiration;
            var updateDate = new Date(emitionDate);
            updateDate.setMonth(updateDate.getMonth() + expirationInt);

            let exp_date = today;

            if (expirationInt === null || expirationInt === 0) {
                expirationInt = 3;
            }

            if (emitionDate == updateDate) {
                exp_date = new Date(emitionDate);
                exp_date.setMonth(exp_date.getMonth() + expirationInt);
                formatted_review.formatted_start = exp_date;
            }

            if (emitionDate < updateDate) {
                exp_date = new Date(updateDate);
                formatted_review.formatted_start = exp_date;
            } else {
                exp_date = new Date(emitionDate);
                exp_date.setMonth(exp_date.getMonth() + expirationInt);
                formatted_review.formatted_start = exp_date;
            }

            const oneMonthBeforeToday = new Date();
            oneMonthBeforeToday.setMonth(oneMonthBeforeToday.getMonth() + 1);
            if (exp_date <= oneMonthBeforeToday && exp_date > today) {
                formatted_review.status = "Por vencer";
                formatted_review.color = "#FFD700";
            } else if (exp_date <= today) {
                formatted_review.status = "No vigente";
                formatted_review.color = "red";
            }

            return formatted_review;
        }

    };

    getDocumentSpecificTemplateStatus(idDoc: string, userRoleId: number): string {
        let has_impression = 'false';
        if (userRoleId === 1) {
            has_impression = 'true';
        } else if (config.has_specific_impression_template.has_step) {
            if (config.has_specific_impression_template.id.includes(idDoc)) {
                has_impression = 'true';
            }
        }
        return has_impression;
    }


}
