import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../app.config';
import { ActivatedRoute } from '@angular/router';
@Injectable({
    providedIn: 'root'
})
export class ApiService {
    api_url: string = '';

    constructor(
        public http: HttpClient,
        public activatedRoute: ActivatedRoute
    ) {
        this.api_url = AppConfig.api_url;
    }

    getHeaders(): HttpHeaders {
        const currentRoute = this.activatedRoute.snapshot['_routerState'].url;
        const params = {}
        const matrixParams = currentRoute.split(';').slice(1).filter((item) => item !== '');
        matrixParams.forEach((matrixParam) => {
            const key = matrixParam.split('=')[0];
            const value = matrixParam.split('=')[1];
            params[key] = value;
        });

        const token = localStorage.getItem('id_token');
        const headers = new HttpHeaders({
            'Authorization': 'Bearer ' + (token ? token : params['d']),
        });
        return headers;
    }


    get(params, list?): any {
        if (list) {
        return this.http.get(this.api_url + params.url, { params: params, headers: this.getHeaders() });
        } else {
        let params_string = '';
        for (let value in params) {
            if (value != "url") { params_string += '/' + params[value]; }
        }
        return this.http.get(this.api_url + params.url + params_string, { headers: this.getHeaders() });
        }
    }

    delete(params: any, list?) {
        if (list) {
        return this.http.delete(this.api_url + params.url, { params: params, headers: this.getHeaders() });
        } else {
        let params_string = '';
        for (let value in params) {
            if (value != "url") { params_string += '/' + params[value]; }
        }
        return this.http.delete(this.api_url + params.url + params_string, { headers: this.getHeaders() });
        }
    }

    post(params): any { 
        return this.http.post(this.api_url + params.url, params, { headers: this.getHeaders() });
    }

    postFile(params): any { 
        return this.http.post(this.api_url + params.url, params, {responseType: 'blob', headers: this.getHeaders()}); 
    }

    postFormData(params: FormData) { 
        return this.http.post(AppConfig.api_url + params.get('url'), params, { headers: this.getHeaders() }); 
    }

    put(params: any) { 
        return this.http.put(this.api_url + params.url, params, { headers: this.getHeaders() }); 
    }
}
