import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

 private previousUrl: string | null = null;
  private currentUrl: string | null = null;

    constructor(private location: Location, private router: Router) {
        this.router.events.subscribe(event => {
          if (event instanceof NavigationStart) {
            this.previousUrl = this.currentUrl;
            this.currentUrl = event.url;
          }
        });

      }
    

    goBackOrHome() {
        const previousUrl = this.previousUrl;
        const referrer = document.referrer;
    
        if (previousUrl && previousUrl !== this.currentUrl && previousUrl !== '/home') {
          this.router.navigateByUrl(previousUrl);
        } else if (window.history.length > 1 && referrer && referrer !== 'about:blank') {
          this.location.back();
        } else {
          this.router.navigate(['/home']);
        }
    }
}
