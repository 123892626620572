import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
    providedIn: 'root'
})
export class RoleService {
    constructor(
        public ApiService: ApiService
    ) { }

    getRoles(params): any { params.url = "list_role"; return this.ApiService.get(params); }
    getPermissions(params): any { params.url = "list_permission"; return this.ApiService.get(params); }
    saveRole(params): any { params.url = "role"; return this.ApiService.post(params); }
    deleteRole(params): any { params.url = "role"; return this.ApiService.delete(params); }
}
