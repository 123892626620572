import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InsideLayoutModule } from '../layout/inside-layout/inside-layout.module';
import { AuthModule } from '../layout/outside-layout/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guards/auth-guard.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { EditGuard } from './guards/edit-guard.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { IntlModule } from '@progress/kendo-angular-intl'; // Importar el módulo de internacionalización de Kendo UI
import { NgSelectModule } from '@ng-select/ng-select';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import '@progress/kendo-angular-intl/locales/es/all'; // Importar la configuración regional de Kendo UI para 'es'

registerLocaleData(localeEs);

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    InsideLayoutModule,
    AuthModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    DateInputsModule,
    IntlModule,         // Asegurarse de importar el módulo de internacionalización de Kendo UI
    NgSelectModule
  ],
  providers: [
    AuthGuard,
    EditGuard,
    NgxImageCompressService,
    { provide: LOCALE_ID, useValue: 'es' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

declare global {
    interface Navigator {
        msSaveBlob: (blob: Blob, fileName: string) => boolean;
        msSaveOrOpenBlob: (blob: Blob, fileName: string) => boolean;
    }
}