import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ComponentInsideLayout } from '../layout/inside-layout/component';
import { ComponentOutsideLayout } from '../layout/outside-layout/outside-component';
import { AuthGuard } from './guards/auth-guard.service';
import { EditGuard } from './guards/edit-guard.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: '/login',
        pathMatch: 'full'
    },
    // {
    //     path: '/**',
    //     redirectTo: '/login'
    // },
    {
        path: '',
        component: ComponentInsideLayout,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'home',
                loadChildren: () => import('../pages/home/home.module').then(m => m.HomeModule),
            },
            {
                path: 'document',
                loadChildren: () => import('../pages/document/dashboard/dashboard.module').then(m => m.DashboardModule),
            },
            {
                path: 'document/folder',
                loadChildren: () => import('../pages/document/folder/folder.module').then(m => m.FolderModule),
                canActivate: [EditGuard]
            },
            {
                path: 'document/list',
                loadChildren: () => import('../pages/document/list/list.module').then(m => m.ListModule),
            },
            {
                path: 'document/master-list',
                loadChildren: () => import('../pages/document/master-list/master-list.module').then(m => m.ListModule),
            },
            {
                path: 'document/create',
                loadChildren: () => import('../pages/document/create/create.module').then(m => m.CreateModule),
                canActivate: [EditGuard]
            },
            {
                path: 'document/flow',
                loadChildren: () => import('../pages/document/flow/flow.module').then(m => m.FlowModule),
                canActivate: [EditGuard]
            },
            {
                path: 'document/approve',
                loadChildren: () => import('../pages/document/approve/approve.module').then(m => m.ApproveModule),
                canActivate: [EditGuard]
            },
            {
                path: 'document/diffusion',
                loadChildren: () => import('../pages/document/diffusion/diffusion.module').then(m => m.DiffusionModule),
                canActivate: [EditGuard]
            },
            {
                path: 'document/rejected',
                loadChildren: () => import('../pages/document/rejected/rejected.module').then(m => m.RejectedModule),
                canActivate: [EditGuard]
            },
            {
                path: 'document/assessment',
                loadChildren: () => import('../pages/document/assessment/assessment.module').then(m => m.AssessmentModule),
            },
            {
                path: 'document/template',
                loadChildren: () => import('../pages/document/template/template.module').then(m => m.TemplateModule),
            },
            {
                path: 'document/indicators',
                loadChildren: () => import('../pages/document/indicators/indicators.module').then(m => m.IndicatorsModule),
            },
            {
                path: 'users-list',
                loadChildren: () => import('../pages/users-list/users-list.module').then(m => m.UsersListModule),
            },
            {
                path: 'document/diffusion-notification',
                loadChildren: () => import('../pages/document/diffusion-notification/diffusion-notification.module').then(m => m.DiffusionNotificationModule),
            },
            {
                path: 'document/extern-file',
                loadChildren: () => import('../pages/document/extern-file/extern-file.module').then(m => m.ExternFileModule),
            },
            {
                path: 'document/folder-extern-file',
                loadChildren: () => import('../pages/document/folder-extern-file/folder-extern-file.module').then(m => m.FolderExternFileModule),
            },
            {
                path: 'user-edit',
                loadChildren: () => import('../pages/user-edit/user-edit.module').then(m => m.UserEditModule),
            },
            {
                path: 'training',
                loadChildren: () => import('../pages/training/training.module').then(m => m.TrainingModule),
            },
            {
                path: 'profile',
                loadChildren: () => import('../pages/profile/profile.module').then(m => m.ProfileModule),
            },
            {
                path: 'methodology',
                loadChildren: () => import('../pages/methodology/methodology.module').then(m => m.MethodologyModule),
            },
            {
                path: 'task',
                loadChildren: () => import('../pages/task/task.module').then(m => m.TaskModule),
            },
            {
                path: 'document/edit',
                loadChildren: () => import('../pages/document/edit/edit.module').then(m => m.EditModule),
            },
            {
                path: 'user-request',
                loadChildren: () => import('../pages/user-request/user-request.module').then(m => m.UserRequestModule),
            },
            {
                path: 'chat',
                loadChildren: () => import('../pages/chat/chat.module').then(m => m.ChatModule),
            },
            {
                path: 'document/template-dashboard',
                loadChildren: () => import('../pages/document/template-dashboard/template-dashboard.module').then(m => m.TemplateDashboardModule),
            },
            {
                path: 'pcc',
                loadChildren: () => import('../pages/pcc/pcc.module').then(m => m.PccModule),
            },
            {
                path: 'haccp',
                loadChildren: () => import('../pages/haccp/haccp.module').then(m => m.HaccpModule),
            },
            {
                path: 'risk-management',
                loadChildren: () => import('../pages/risk-management/risk-management.module').then(m => m.RiskManagementModule),
            },
            {
                path: 'specification',
                loadChildren: () => import('../pages/specification/specification.module').then(m => m.SpecificationModule),
            },
            {
                path: 'flowchart',
                loadChildren: () => import('../pages/flowchart/flowchart.module').then(m => m.FlowchartModule),
            },
            {
                path: 'hazard-analysis',
                loadChildren: () => import('../pages/hazard-analysis/hazard-analysis.module').then(m => m.HazardAnalysisModule),
            },
            {
                path: 'haccp-plan',
                loadChildren: () => import('../pages/haccp-plan/haccp-plan.module').then(m => m.HaccpPlanModule),
            },
            {
                path: 'monitoring',
                loadChildren: () => import('../pages/monitoring/monitoring.module').then(m => m.MonitoringModule),
            },
            {
                path: 'verification-risks',
                loadChildren: () => import('../pages/verification-risks/verification-risks.module').then(m => m.VerificationRisksModule),
            },
            {
                path: 'actions-record',
                loadChildren: () => import('../pages/actions-record/actions-record.module').then(m => m.ActionsRecordModule),
            },
            {
                path: 'action-create',
                loadChildren: () => import('../pages/action-create/action-create.module').then(m => m.ActionCreateModule),
            },
            {
                path: 'template-index',
                loadChildren: () => import('../pages/audit-inspection/template/index/module').then(m => m.Module),
            },
            {
                path: 'template-save',
                loadChildren: () => import('../pages/audit-inspection/template/save/module').then(m => m.Module),
            },
            {
                path: 'diary',
                loadChildren: () => import('../pages/audit-inspection/diary/module').then(m => m.Module),
            },
            {
                path: 'start-inspection',
                loadChildren: () => import('../pages/audit-inspection/inspection/start-inspection/module').then(m => m.Module),
            },
            {
                path: 'inspection',
                loadChildren: () => import('../pages/audit-inspection/inspection/inspection/module').then(m => m.Module),
            },
            {
                path: 'action',
                loadChildren: () => import('../pages/audit-inspection/inspection/action/module').then(m => m.Module),
            },
            {
                path: 'report',
                loadChildren: () => import('../pages/audit-inspection/inspection/report/module').then(m => m.Module),
            },
            {
                path: 'analysis',
                loadChildren: () => import('../pages/audit-inspection/analysis/module').then(m => m.Module),
            },
            {
                path: 'general-report',
                loadChildren: () => import('../pages/audit-inspection/general-report/module').then(m => m.Module),
            },
            {
                path: 'document/document-request',
                loadChildren: () => import('../pages/document/document-request/document-request.module').then(m => m.DocumentRequestModule),
            },
            {
                path: 'notification-inbox',
                loadChildren: () => import('../pages/notification-inbox/notification-inbox.module').then(m => m.NotificationInboxModule),
            },
            {
                path: 'role',
                loadChildren: () => import('../pages/role/role.module').then(m => m.RoleModule),
            }
        ]
    },
    {
        path: '',
        component: ComponentOutsideLayout,
        canActivateChild: [AuthGuard],
        children: [
            {
                path: 'login',
                loadChildren: () => import('../pages/auth/login/login.module').then(m => m.LoginModule),
            },
            {
                path: 'register',
                loadChildren: () => import('../pages/auth/register/register.module').then(m => m.RegisterModule),
            }
        ]
    },
    {
        path: '',
        component: ComponentOutsideLayout,
        children: [
            {
                path: 'view-diffusion',
                loadChildren: () => import('../pages/view-diffusion/view-diffusion.module').then(m => m.ViewDiffusionModule)
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule]
})
export class AppRoutingModule { }
