import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { tap, firstValueFrom } from 'rxjs';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, take } from 'rxjs';

@Component({
    selector: 'app-notifications-sidebar',
    templateUrl: './notifications-sidebar.component.html',
    styleUrls: ['./notifications-sidebar.component.css']
})

export class NotificationsSidebarComponent implements OnInit {
    
        @ViewChild('sidebar') notificationsSidebar;
        @ViewChild('sidebarcontent') notificationsSidebarContent;
        @ViewChildren('notificationslist', {read: ElementRef}) notificationsList;
        public listSubscription: any;

        public busyGettingNotifications = true;

        // private new_notifications_interval: any;
        // if(!this.new_notifications_interval) {
        //     this.new_notifications_interval = setInterval(() => {
        //         if(this.Notifications.length == 0) {
        //             // app.loadNotifications();
        //         } else {
        //             this.NotificationService.getNewerNotifications({to_user_id: this.User.id, last_notif: this.Notifications[0].id}).subscribe((return_data) => {
        //                 if(return_data.success) {
        //                     this.Notifications = [...return_data.data, ...this.Notifications]
        //                 }
        //             })
        //         }
        //     }, 15000);
        // }
        // ngOnDestroy() {
        //     clearInterval(this.new_notifications_interval);
        // }

        public notifications$: Observable<Notification[]>;
        public user:any;
        allNotifications: any[] = [];
        selectedNotificationTypes: string[] = []; 
        public notificationTypes = [
            { type: "document_request_update", name: "Actualización solicitud" },
            { type: "review_error", name: "Error en revisión" },
            { type: "review_success", name: "Revisión exitosa" },
            { type: "package_review_success", name: "Éxito en revisión del paquete" },
            { type: "asign_diffusion", name: "Asignación de difusión" },
            { type: "review_rejection", name: "Rechazo en revisión" },
            { type: "user_request", name: "Solicitud de usuario" },
            { type: "difussion_success", name: "Difusión exitosa" },
            { type: "review", name: "Revisión" }
        ];

        constructor(
            private notificationService: NotificationService,
            private userService: UserService,
            private router: Router,

        ) { }


    
        async ngOnInit() {
            await this.getUser();
            await this.getNotifications();

            setTimeout(() => {
                this.notificationsSidebar.nativeElement.classList.add('active');
            }, 1);

            this.listSubscription = this.notificationsList.changes.subscribe((next: any) => {
                if (this.notificationsSidebarContent.nativeElement.offsetHeight > next.first.nativeElement.scrollHeight) {
                    this.notificationService.loadMoreSidebarNotifications();
                }

                this.listSubscription.unsubscribe();
            });

            this.router.events.subscribe(async (event) => {
                if (event instanceof NavigationEnd) {
                    await this.getNotifications();
                }
            });
        }

        async getNotifications() {
            this.allNotifications = this.notificationService.allNotifications;
            this.busyGettingNotifications = false;
            if (this.selectedNotificationTypes.length > 0) {                
                this.allNotifications = this.allNotifications.filter(notification => {
                    return this.selectedNotificationTypes.includes(notification.type);
                });                
            }
        }
        
        async onNotificationTypeSelect(selectedTypes: any) {
            this.selectedNotificationTypes = selectedTypes.map(selected => selected.type);
            this.getNotifications();
        }        
          
        ngOnDestroy() {
            this.listSubscription.unsubscribe();
        }
    
        // Get perfil de mi usuario
        async getUser(): Promise<void> {
            const user = await firstValueFrom<any>(this.userService.retrieveUser());
            if (user) {
                this.user = user;
            } 
        }
        
        closeNotificationsSidebar() {
            this.notificationsSidebar.nativeElement.classList.remove('active');
            setTimeout(() => {
                this.notificationService.closeNotificationsSidebar();
            }, 300);
        }

        async onScroll(event: any) {
            if (event.target.offsetHeight + event.target.scrollTop >= event.target.scrollHeight - 50) {
                if (!this.busyGettingNotifications) {
                    this.busyGettingNotifications = true;
                    try {
                        this.notificationService.loadMoreSidebarNotifications();
                    } finally {
                        await this.getNotifications();
                    }
                }
            }
        }
}